import React, { useState, useEffect, useCallback, useRef } from "react";
import "../Components/PauseAddProperty.css";
import Pluse from "../Assets/Images/Addbutn.png";
import Button from "react-bootstrap/Button";
import Mal from "../Assets/Images/Maltepalplus.png";
import { useFormik } from "formik";
import { PauseAddSchemas } from "../Schemas";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "../Schemas/Api";
import { toast } from "react-toastify";
import { Backdrop, CircularProgress } from "@mui/material";
import LoginNavbar from "../Components/LoginNavbar";
import Footer from "../Components/Footer";
import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";
import Modal from "react-bootstrap/Modal";
import TimePicker from "react-time-picker";
import back from "../Assets/Images/Backicon.png";
import plus from "../Assets/Images/plus.svg";
import remove from "../Assets/Images/delete.svg";
import {
  Airconditioning,
  BathoomEssentials,
  BedroomEssentials,
  CheckinMethod,
  Children,
  Families,
  Gardenview,
  Kitchen,
  Kitchenette,
  Security,
  Sharespace,
  StepFree,
  HouseRules,
} from "../Components/Checkboxdata";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

const initialValues = {
  title: "",
  Specify: "",
  city: "",
  state: "",
  zipcode: "",
  price: "",
  category: "",
  bedsheetsothors: "",
  aircondiothors: "",
  checkinMethodothors: "",
  kitchanothors: "",
  childothors: "",
  leanFessothors: "",
  bedroomsothors: "",
  bathroomothars: "",
  amenities: "",
  Size: "",
  sizeunety: "",
  fname: "",
  Houseruleshr: "",
  gardenviewothors: "",
  Neighborhood: "",
  Property: "",
  policy: "",
  Maximum: "",
  Maximumc: "",
};

const PauseAddProperty = () => {
  // let route = useLocation();
  // const continuedata = route.state.data;
  // console.log(continuedata);

  const Maines = (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 11H13H17V13H13V13H11V13H7V11H11ZM12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87827 20 12 20Z"
        fill="black"
      />
    </svg>
  );
  const [conproperty, setConproperty] = useState([]);
  const [Getcategory, setGetcategory] = useState([]);
  // console.log(Getcategory, "Getcategory");
  const [show, setShow] = useState();
  const [isButton1Clicked, setIsButton1Clicked] = useState(false);
  const [isButton2Clicked, setIsButton2Clicked] = useState(false);
  const [Isoterhbeds, setIsoterhbeds] = useState(false);
  const [IsotherView, setIsotherView] = useState(false);
  const [IsotherBathRooms, setIsotherBathRooms] = useState(false);
  const [IsotherCheckMethod, setIsotherCheckMethod] = useState(false);

  const [IsotherCheckMethod1, setIsotherCheckMethod1] = useState(false);

  const [IsOtherCleanFess, setIsOtherCleanFess] = useState(false);
  const [OtherClaenFees, setOtherClaenFees] = useState("");
  const [IsotherKichen, setIsotherKichen] = useState(false);
  // const [errors, setErrors] = useState({});
  const [IsotherKichen1, setIsotherKichen1] = useState(false);
  const [IsotherHouseRules, setIsotherHouseRules] = useState(false);
  const [sharespace1, setSharespace1] = useState(false);
  const [IsotherChildern, setIsotherChildern] = useState(false);
  const [Dispose, setDispose] = useState(false);
  const [kitchan, setkitchan] = useState(Kitchen);
  //usesssssssssssssssssss
  const [airconditioning, setAirconditioning] = useState(false);
  const [sharespace, setsharespace] = useState(Sharespace);
  const [kitchenette, setketKitchenette] = useState(Kitchenette);
  const [children, setChildren] = useState(Children);
  const [stepFree, setStepFree] = useState(StepFree);
  const [checkinMethod, setCheckinMethod] = useState(CheckinMethod);
  const [gardenview, setGardenview] = useState(Gardenview);
  const [security, setSecurity] = useState(Security);
  const [House, setHouse] = useState(HouseRules);
  const [families, setFamilies] = useState(Families);
  const token = JSON.parse(localStorage.getItem("login"));

  const url = JSON.parse(localStorage.getItem("URL"));

  // Time usestat----------------------------------
  const [valueeeee, onChangeeee] = useState("");
  const [out, onOut] = useState("");
  const [noise, onNoise] = useState("");
  const [pool, onPool] = useState("");
  const [tub, onTub] = useState("");
  // Time usestat----------------------------------No noise after

  const [selectedCheckBox, setselectedCheckBox] = useState("");
  const [showInput, setShowInput] = useState(false);
  const [Model, setModel] = useState(false);
  const [free, setFree] = useState(false);

  const [cleningFee, setCleningFee] = useState();
  const [AddMore, setAddMore] = useState([{ qty: "", bed_type: "" }]);
  const [AddBathroom, setAddBathroom] = useState([
    { qty: "", bathoroom_type: "" },
  ]);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorbathroom, setErrorbathroom] = useState("");
  const [numBedrooms, setNumBedrooms] = useState(1);
  const [numBathrooms, setNumBathrooms] = useState(1);
  const [bedrooms, setBedrooms] = useState([{ id: 1, beds: [{ id: 1, type: "", qty: 1 }] }]);
  const [bathrooms, setBathrooms] = useState([{ id: 1, beds: [{ id: 1, type: "", qty: 1 }] }]);

  const bedTypes = ["Double bed", "Single bed", "Baby crib", "Bunk bed", "Queen bed", "King bed"];
  const bathroomTypes = ["Shower", "Bathtub", "Hydromassage Shower", "Bathtub with shower"];

  const generateBedrooms = (num) => {
    const newBedrooms = [];
    for (let i = 0; i < num; i++) {
      newBedrooms.push({
        id: i + 1,
        beds: [{ id: 1, type: "", qty: 1 }]
      });
    }
    setBedrooms(newBedrooms);
  };

  const generateBathrooms = (num) => {
    const newBathrooms = [];
    for (let i = 0; i < num; i++) {
      newBathrooms.push({
        id: i + 1,
        beds: [{ id: 1, type: "", qty: 1 }]
      });
    }
    setBathrooms(newBathrooms);
  };

  const addBed = (roomId, isBathroom = false) => {
    const setter = isBathroom ? setBathrooms : setBedrooms;
    setter(prevRooms => {
      const newRooms = prevRooms.map(room => {
        if (room.id === roomId) {
          return {
            ...room,
            beds: [...room.beds, { id: room.beds.length + 1, type: "", qty: 1 }]
          };
        }
        return room;
      });
      return newRooms;
    });
  };

  const removeBed = (roomId, bedId, isBathroom = false) => {
    const setter = isBathroom ? setBathrooms : setBedrooms;
    setter(prevRooms => {
      const newRooms = prevRooms.map(room => {
        if (room.id === roomId) {
          return {
            ...room,
            beds: room.beds.filter(bed => bed.id !== bedId)
          };
        }
        return room;
      });
      return newRooms;
    });
  };

  const handleBedTypeChange = (roomId, bedId, type, isBathroom = false) => {
    const setter = isBathroom ? setBathrooms : setBedrooms;
    setter(prevRooms => {
      const newRooms = prevRooms.map(room => {
        if (room.id === roomId) {
          return {
            ...room,
            beds: room.beds.map(bed => bed.id === bedId ? { ...bed, type } : bed)
          };
        }
        return room;
      });
      return newRooms;
    });
  };

  const handleBedQtyChange = (roomId, bedId, qty, isBathroom = false) => {
    const setter = isBathroom ? setBathrooms : setBedrooms;
    setter(prevRooms => {
      const newRooms = prevRooms.map(room => {
        if (room.id === roomId) {
          return {
            ...room,
            beds: room.beds.map(bed => bed.id === bedId ? { ...bed, qty } : bed)
          };
        }
        return room;
      });
      return newRooms;
    });
  };
  // console.log(free);
  const handleCheckboxChange = (val) => {
    // console.log(val);
    setselectedCheckBox(selectedCheckBox == val ? "" : val);

    if (val === "0" && free === true) {
      // setShowInput(!showInput);
      setModel(true);
    } else if (val === "1" && free === false) {
      setShowInput(!showInput);
    }
  };

  // console.log(Model);
  // console.log(selectedCheckBox);

  const Popup = (props) => {
    return (
      <>
        <Modal
          {...props}
          size="sm"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <div className="Property-card-divv-ppx">
            <div className="Property-p-cvv mt-2">
              <span className="spandivv-ppx-property">
                Are you sure you want to log out?
              </span>
            </div>

            <div className="Button-divv-prop ">
              <div className="btnn-divv-pro-later">
                <button
                  onClick={() => setModel(false)}
                  className="btn-pro-later-main"
                >
                  close
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  };
  // console.log(valueeeee, "timemils");
  // console.log(out, "out");
  // console.log(noise, "noise");
  // console.log(pool, "pool");
  // console.log(tub, "tub");

  const getcategory = async () => {
    const formdata = new FormData();
    // formdata.append("property_id", id);
    try {
      let cofe = {
        url: "/get_category",
        method: "Get",
        data: formdata,
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          authorization: `${token.data.token.token}`,
        },
      };
      axios(cofe)
        .then((res) => res.data)
        .then((respons) => {
          // console.log(respons, "cococcoc");
          setGetcategory(respons.category_list);
        });
    } catch (error) {
      // console.log("userap...", error);
      toast.error(error.response.message, {
        position: "top-right",
        theme: "colored",
      });
    }
  };
  useEffect(() => {
    getcategory();
  }, []);

  // const Getstripe = async () => {
  //   try {
  //     let pay = {
  //       url: "/verify_account",
  //       method: "Get",
  //       headers: {
  //         "Content-Type": "multipart/form-data",
  //         Accept: "application/json",
  //         authorization: `${token.data.token.token}`,
  //       },
  //     };
  //     axios(pay)
  //       .then((res) => res.data)
  //       .then((response) => {
  //         localStorage.setItem("URL", JSON.stringify(response));
  //         // console.log(response, "gsdgfsgdsg");
  //         // setPay(response.data.url);
  //       });
  //   } catch (error) {
  //     // console.log(error);
  //   }
  // };
  // useEffect(() => {
  //   Getstripe();
  // }, []);
  // console.log(token.token);

  // Api
  const [Policies, setPolicies] = useState([]);
  const [Loading, setLoading] = useState(false);
  // console.log(Policies);
  const Policy = async () => {
    const formdata = new FormData();
    setLoading(true);
    try {
      let poly = {
        url: "/get_cancellation_policies",
        method: "Get",
        data: formdata,
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          authorization: `${token.data.token.token}`,
        },
      };
      axios(poly)
        .then((res) => res.data)
        .then((respons) => {
          setPolicies(respons.data);
          setLoading(false);
        });
    } catch (error) {
      // console.log("userap...", error);
      setLoading(false);
      toast.error(error.response.message, {
        position: "top-right",
        theme: "colored",
      });
    }
  };
  useEffect(() => {
    Policy();
  }, []);

  //add item
  const [fields, setFields] = useState([{ value: "", id: 0 }]);
  const [fields2, setFields2] = useState([{ value: "", id: 0 }]);
  // console.log(fields2,"kamil")

  // // console.log({ fields });
  // const handleAdd = useCallback(() => {
  //   const values = [...fields];
  //   values.push({
  //     value: null,
  //     id: values.length,
  //   });
  //   setFields(values);
  // }, [fields]);

  // const handleAdd3 = useCallback(() => {
  //   const values = [...fields2];
  //   values.push({
  //     value: null,
  //     id: values.length,
  //   });
  //   setFields2(values);
  // }, [fields2]);

  // function handledeleted(id) {
  //   // console.log("id", id);
  //   setFields((data) => {
  //     return data.filter((item) => {
  //       // console.log("iteam", item.id);
  //       return item.id != id;
  //     });
  //   });
  // }
  // function handledeleted2(id) {
  //   // console.log("id", id);
  //   setFields2((data) => {
  //     return data.filter((item) => {
  //       // console.log("iteam", item.id);

  //       return item.id != id;
  //     });
  //   });
  // }
  // const handleChangeinput2 = useCallback(
  //   (i, event) => {
  //     // console.log(i);
  //     const values = [...fields2];
  //     values[i].value = event.target.value;
  //     setFields2(values);
  //   },
  //   [fields2]
  // );
  // const handleChangeinput = useCallback(
  //   (i, event) => {
  //     // console.log(i);
  //     const values = [...fields];
  //     values[i].value = event.target.value;
  //     setFields(values);
  //   },
  //   [fields]
  // );
  // console.log(url.data.url, "duhfdshufhsd");

  const preventPasteNegative = (e) => {
    const clipboardData = e.clipboardData || window.clipboardData;
    const pastedData = parseFloat(clipboardData.getData("text"));

    if (pastedData < 0) {
      e.preventDefault();
    }
  };
  const preventMinus = (e) => {
    if (e.code === "Minus") {
      e.preventDefault();
    }
  };
  const Payment = (props) => {
    return (
      <>
        <Modal
          {...props}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <div className="Payment-card-divv-ppx">
            <div className="Payment-ppx-cvv mt-2">
              <span className="spandivv-ppx-pay">
                Do you want to verify account?
              </span>
            </div>

            <div className="Button-divv-pay">
              <div className="btnn-divv-class">
                <a
                  href={url?.data?.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="a-link-tagg-btnn"
                >
                  <button className="btn-pay-stripe-main">Yes</button>
                </a>
              </div>
              <div className="btnn-divv-sec-later">
                <button
                  onClick={() => Navigat("/MyProfile/MyProperty")}
                  className="btn-stripe-later-main"
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  };

  const Navigat = useNavigate();
  const [loading, setloading] = useState(false);
  const [lat, setlat] = useState(null);
  const [address, setAddress] = useState("");
  const [images, setImages] = useState([]);
  // console.log(images, "fileaa");
  const [BedRooms, setBedRooms] = useState(BedroomEssentials);
  const [policyid, setpolicyid] = useState("");
  const [Bathroom, setBathroom] = useState(BathoomEssentials);
  const [Aircondi, setAircondi] = useState(Airconditioning);
  // const [Checkin, Setcheckin] = useState(CheckinMethod);
  const [Child, Setchild] = useState(Children);
  const [Familie, setfamilie] = useState(Families);
  let route = useLocation();
  // console.log("🚀 ~ file: AddProperty.js:726 ~ AddProperty ~ route:", route);
  const dataget = route.state == null ? null : route.state.arr.property_detail;
  // console.log(dataget.cancellation_policy.id, "helllooooooo");
  const inputref = useRef();
  const onSubmit = async () => {
    // console.log("hhhhhhhhhhhhh");
    // console.log(values);
    if (images.length === 0) {
      // toast.error("Please select at least one image.");
      toast.error("Please select at least one image.", {
        position: "top-right",
        theme: "colored",
      });
      setloading(false);
      return; // Exit the function if no images are selected
    }

    // console.log("clickkkkkkk");
    setloading(true);
    const formdata = new FormData();
    if (fields2)
      BedRooms.map((item) => {
        formdata.append(item.backEndName, item.isSelected == true ? 1 : 0);
      });
    stepFree.map((item) => {
      formdata.append(item.backEndName, item.isSelected == true ? 1 : 0);
    });
    Bathroom.map((item) => {
      formdata.append(item.backEndName, item.isSelected == true ? 1 : 0);
    });
    Aircondi.map((item) => {
      formdata.append(item.backEndName, item.isSelected == true ? 1 : 0);
    });
    Child.map((item) => {
      formdata.append(item.backEndName, item.isSelected == true ? 1 : 0);
    });
    security.map((item) => {
      formdata.append(item.backEndName, item.isSelected == true ? 1 : 0);
    });
    kitchan.map((item) => {
      formdata.append(item.backEndName, item.isSelected == true ? 1 : 0);
    });
    kitchenette.map((item) => {
      formdata.append(item.backEndName, item.isSelected == true ? 1 : 0);
    });
    sharespace.map((item) => {
      formdata.append(item.backEndName, item.isSelected == true ? 1 : 0);
    });
    Familie.map((item) => {
      formdata.append(item.backEndName, item.isSelected == true ? 1 : 0);
    });
    gardenview.map((item) => {
      formdata.append(item.backEndName, item.isSelected == true ? 1 : 0);
    });
    checkinMethod.map((item) => {
      formdata.append(item.backEndName, item.isSelected == true ? 1 : 0);
    });
    House.map((item) => {
      formdata.append(item.backEndName, item.isSelected == true ? 1 : 0);
    });

    formdata.append("type_of_property", "safa");
    formdata.append("type_of_place", "jna");
    formdata.append("cleaning_fees", cleningFee);
    formdata.append("free_cleaning_service", selectedCheckBox ? 1 : 0);
    formdata.append("other_airconditioning_essentials", values.aircondiothors);
    formdata.append("other_bathroom_essentials", values.bathroomothars);
    formdata.append("other_bedroom_essentials", values.bedroomsothors);
    formdata.append("other_kitchen_essentials", values.kitchanothors);
    formdata.append("other_baby_essentials", values.childothors);
    formdata.append("other_checkin_essentials", values.checkinMethodothors);
    formdata.append("other_amenities", values.amenities);
    // formdata.append("other_amenities", values.amenities);
    formdata.append("other_view", values.gardenviewothors);

    formdata.append("Houseruleshr", values.Houseruleshr);

    formdata.append("neighbourhood_description", values.Neighborhood);
    formdata.append("property_size", values.Size);
    formdata.append("property_size_unit", values.sizeunety);
    formdata.append("", values.sizeunety);

    for (var pair of formdata.entries()) {
      // console.log(pair[0], pair[1]);
      // console.log(formdata.entries().next().value);
    }
    formdata.append("title", values.title);
    formdata.append("latitude", lat?.lat);
    // console.log("aaaaaaaaaaaa", lat.lat);
    formdata.append("longitude", lat?.lng);
    formdata.append("address", address);
    formdata.append("city", values.city);
    formdata.append("state", values.state);
    formdata.append("zipcode", values.zipcode);
    formdata.append("guest_space", values.guest_space);
    formdata.append("bedrooms", numBedrooms);
    // console.log(values.bedrooms, "bed value");
    // formdata.append("beds", AddMore);
    // AddMore.forEach((item) => {
    formdata.append("beds", JSON.stringify(bedrooms));
    //   console.log(item);
    // });
    formdata.append("adult", values.Maximum);
    formdata.append("child", values.Maximumc);
    // AddBathroom.forEach((item) =>
    formdata.append("bathrooms", JSON.stringify(bathrooms));
    // );
    formdata.append("checkin_time", valueeeee);
    formdata.append("checkout_time", out);
    formdata.append("no_noise_after", noise);
    formdata.append("swimming_pool_time", pool);
    formdata.append("hot_tub_time", tub);
    formdata.append("price_per_night", values.price);
    formdata.append("category_id", values.category);
    formdata.append("cancellation_policy_id", policyid ? policyid : dataget.cancellation_policy.id);
    formdata.append("neighbourhood_description", values.Neighborhood);
    formdata.append("about_property", values.Property);
    formdata.append("leave_bedsheets_and_towels", values.Specify);
    formdata.append("free_cleaning_service", "dffg");
    formdata.append("type_of_place", "Private");

    for (let i = 0; i < images.length; i++) {
      formdata.append("images", images[i]);
    }

    // console.log(formdata, "for ");
    try {
      const res = await axios({
        url: "/add_property",
        method: "post",
        data: formdata,
        headers: {
          "Content-Type": "multipart/form-data",
          authorization: `${token.data.token.token}`,
          // authorization:
          //   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MTE5LCJleHAiOjE3MTUxMDM2NTV9.wPMGYdBuDRMGSOvoWfZ69KXS4MGf-7D6S002JJhQeGU",
        },
      });

      // console.log("resss...", token.data.token.token);
      if (res.data.status == 0) {
        toast.error(res.data.message, {
          position: "top-right",
          theme: "colored",
        });
      } else if (res.data.status == 1) {
        toast.success(res.data.message, {
          position: "top-right",
          theme: "colored",
        });
      }
      console.log(res, "ggggggg");
      // if (res.data.message === "Property Added Successfully") {
      //   toast.success(res.data.message, {
      //     position: "top-right",
      //     theme: "colored",
      //   });
      // }
      if (res.data.data.property_detail.is_stripe_account_verified == false) {
        setShow(true);
      } else if (
        res.data.data.property_detail.is_stripe_account_verified == true
      ) {
        Navigat("/MyProfile/MyProperty");
      }
      setloading(false);
    } catch (error) {
      console.log("HJGHGH", error);
      setloading(false);
      toast.error(error.response.message, {
        position: "top-right",
        theme: "colored",
      });
    }
  };
  // console.log(dataget.cancellation_policy.id);
  // console.log("Bedrooms array:", bedrooms);
  // console.log("Bathrooms array:", bathrooms);
  // console.log("Number of Bedrooms:", numBedrooms);
  // console.log("Number of Bathrooms:", numBathrooms);
  // const Pause2 = () => {
  //   console.log("Hii beta dusra func call ho gaya");
  // };
  const getimage = route.state == null ? null : route.state.arr.images;

  const Pause = async () => {
    if (values.title === "") {
      // console.log("Title is empty. Aborting Pause function.");
      toast.error("Please fill all fields", {
        position: "top-right",
        theme: "colored",
      });
      return; // Exit the function if the title is empty
    } else {
      setloading(true);
      setIsButton1Clicked(true);
      setIsButton2Clicked(false);
      const formdata = new FormData();
      BedRooms.map((item) => {
        formdata.append(item.backEndName, item.isSelected == true ? 1 : 0);
      });
      stepFree.map((item) => {
        formdata.append(item.backEndName, item.isSelected == true ? 1 : 0);
      });
      Bathroom.map((item) => {
        formdata.append(item.backEndName, item.isSelected == true ? 1 : 0);
      });
      Aircondi.map((item) => {
        formdata.append(item.backEndName, item.isSelected == true ? 1 : 0);
      });
      Child.map((item) => {
        formdata.append(item.backEndName, item.isSelected == true ? 1 : 0);
      });
      security.map((item) => {
        formdata.append(item.backEndName, item.isSelected == true ? 1 : 0);
      });
      kitchan.map((item) => {
        formdata.append(item.backEndName, item.isSelected == true ? 1 : 0);
      });
      kitchenette.map((item) => {
        formdata.append(item.backEndName, item.isSelected == true ? 1 : 0);
      });
      sharespace.map((item) => {
        formdata.append(item.backEndName, item.isSelected == true ? 1 : 0);
      });
      Familie.map((item) => {
        formdata.append(item.backEndName, item.isSelected == true ? 1 : 0);
      });
      gardenview.map((item) => {
        formdata.append(item.backEndName, item.isSelected == true ? 1 : 0);
      });
      checkinMethod.map((item) => {
        formdata.append(item.backEndName, item.isSelected == true ? 1 : 0);
      });
      House.map((item) => {
        formdata.append(item.backEndName, item.isSelected == true ? 1 : 0);
      });

      formdata.append("type_of_property", "safa");
      formdata.append("type_of_place", "jna");
      formdata.append("cleaning_fees", values.leanFessothors);
      formdata.append("cleaning_fees", cleningFee);
      formdata.append("free_cleaning_service", selectedCheckBox ? 1 : 0);
      formdata.append(
        "other_airconditioning_essentials",
        values.aircondiothors
      );
      formdata.append("other_bathroom_essentials", values.bathroomothars);

      formdata.append("other_bedroom_essentials", values.bedroomsothors);
      // console.log(values.bedroomsothors, "bedroomsothors");
      formdata.append("other_kitchen_essentials", values.kitchanothors);
      formdata.append("other_baby_essentials", values.childothors);
      formdata.append("other_checkin_essentials", values.checkinMethodothors);
      formdata.append("other_amenities", values.amenities);
      formdata.append("other_amenities", values.amenities);
      formdata.append("Houseruleshr", values.Houseruleshr);
      formdata.append("neighbourhood_description", values.Neighborhood);
      formdata.append("property_size", values.Size);
      formdata.append("property_size_unit", values.sizeunety);
      formdata.append("", values.sizeunety);

      for (var pair of formdata.entries()) {
        // console.log(pair[0], pair[1]);
        // console.log(formdata.entries().next().value);
      }
      formdata.append("title", values.title);
      formdata.append("latitude", lat?.lat);
      formdata.append("longitude", lat?.lng);
      formdata.append("address", address);
      formdata.append("city", values.city);
      formdata.append("state", values.state);
      formdata.append("zipcode", values.zipcode);
      formdata.append("guest_space", values.guest_space);
      formdata.append("bedrooms", numBedrooms);
      // console.log(values.bedrooms, "bedrooms value ");
      formdata.append("beds", JSON.stringify(bedrooms));
      formdata.append("adult", values.Maximum ? parseInt(values.Maximum) : 1);
      formdata.append("child", values.Maximumc ? parseInt(values.Maximumc) : 0);
      formdata.append("bathrooms", JSON.stringify(bathrooms));
      formdata.append("checkin_time", valueeeee);
      formdata.append("checkout_time", out);
      formdata.append("no_noise_after", noise);
      formdata.append("swimming_pool_time", pool);
      formdata.append("hot_tub_time", tub);
      // formdata.append("house_rules", JSON.stringify(fields2));
      // formdata.append("house_rules", JSON.stringify([{ id: 133, value: "Lorem ipsum dolor amit" },{ id: 1, value: "Lorem ipsum dolor amit" },]));
      formdata.append("price_per_night", values.price);
      formdata.append("category_id", values.category);
      formdata.append("cancellation_policy_id", policyid);
      formdata.append("neighbourhood_description", values.Neighborhood);
      formdata.append("about_property", values.Property);
      formdata.append("leave_bedsheets_and_towels", values.Specify);
      formdata.append("type_of_place", "Private");
      formdata.append("property_id", dataget.id);

      // for (var key of formdata.entries()) {
      //   console.log(
      //     "🚀 ~ file: AddProperty.js:343 ~ onSubmit ~ key:",
      //     key[0],
      //     key[1]
      //   );
      // }
      for (let i = 0; i < images.length; i++) {
        formdata.append("images", images[i]);
      }
      try {
        const res = await axios({
          url: "/edit_pending_property",
          method: "post",
          data: formdata,
          headers: {
            "Content-Type": "multipart/form-data",
            authorization: `${token.data.token.token}`,
          },
        });

        // console.log("resss...", res);
        if (res.data.status === 1) {
          // console.log(res, "fgdfdfdfuygh");
          localStorage.setItem(
            "Pauseid",
            JSON.stringify(res.data.data.property_detail.id)
          );
          Navigat("/MyProfile/ContinueProperty");
          toast.success(res.data.message, {
            position: "top-right",
            theme: "colored",
          });
        } else if (res.data.status === 0) {
          toast.error(res.data.message, {
            position: "top-right",
            theme: "colored",
          });
        }
        // if (res.data.data.property_detail.is_stripe_account_verified == false) {
        //   setShow(true);
        // } else if (
        //   res.data.data.property_detail.is_stripe_account_verified == true
        // ) {
        //   Navigat("/MyProfile/MyProperty");
        // }
        setloading(false);
      } catch (error) {
        // console.log("HJGHGH", error);
        if (error.message === "Network Error") {
          localStorage.removeItem("login");
          Navigat("/");
          toast.error(error.message, {
            position: "top-right",
            theme: "colored",
          });
        }
        setloading(false);
        toast.error(error.response.message, {
          position: "top-right",
          theme: "colored",
        });
      }
    }
    // console.log(".......click");
  };

  const FixedButton = () => {
    const [isFixed, setIsFixed] = useState(false);

    useEffect(() => {
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }, []);

    const handleScroll = () => {
      const scrollThreshold = 200; // Adjust this value to determine when to fix the button
      setIsFixed(window.pageYOffset > scrollThreshold);
    };
    return (
      <>
        <button
          type="button"
          onClick={Pause}
          // onClick={Pause2}
          className={`fixed-button ${isFixed ? "fixed" : ""}`}
        >
          {/* Pause */}
          Save
        </button>
      </>
    );
  };

  const GetSavelist = () => {
    axios({
      method: "get",
      url: "/get_pending_property_list",
      headers: {
        authorization: `${token.data.token.token}`,
      },
    })
      .then((res) => res.data)
      .then((data) => {
        setConproperty(data);
        // console.log(data, "dffsfdsfsf");
      })
      .catch((err) => {
        // console.log(err);
      });
  };
  useEffect(() => {
    GetSavelist();
  }, []);

  const {
    handleBlur,
    handleChange: handle,
    handleSubmit,
    setValues,
    setFieldValue,
    touched,
    errors,
    values,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: PauseAddSchemas,
    onSubmit,
  });
  // console.log(values.kitchanothors, "kitchen others");

  // console.log(values.price, "ters price");
  // console.log(values.kitchanothors, "kitchan others");

  const handleButton2Click = () => {
    setIsButton1Clicked(false);
    setIsButton2Clicked(true);
    // MainPuse();
    // handleSubmit();
  };
  // ADD Api

  // console.log(getimage, "asgdysda");
  useEffect(() => {
    if (dataget == null) {
      return;
    } else {
      const Mainsetvalues = {
        title: dataget.title,
        address: dataget.address,
        city: dataget.city,
        state: dataget.state,
        zipcode: dataget.zipcode,
        category: dataget.category_id,
        bedrooms: dataget.bedrooms,
        Bathrooms: dataget.bathroom,
        beds: dataget.beds,
        price: dataget.price_per_night,
        sizeunety: dataget.property_size_unit,
        Size: dataget.property_size,
        Maximum: dataget.adult,
        Maximumc: dataget.child,
        policy: dataget.cancellation_policy.id.toString(),
        Neighborhood: dataget.neighbourhood_description,
        Property: dataget.about_property,
      };
      setValues(Mainsetvalues);
      setAddress(dataget.address);
      setpolicyid(dataget.cancellation_policy.id);
      setlat(dataget.latLng);
      setAddMore(dataget?.beds);
      setAddBathroom(dataget?.bathroom);
    }
  }, [dataget]);

  // console.log(values.Specify, "edcvghgcfgyuhg");
  const [imageCount, setImageCount] = useState(0);
  const handleImageChange = (event) => {
    const selectedImages = Array.from(event.target.files);

    if (imageCount + selectedImages.length <= 15) {
      setImages([...images, ...selectedImages]);
      setImageCount(imageCount + selectedImages.length);
    } else {
      alert(
        `You can only select a maximum of 15 images. You tried to select ${selectedImages.length} images.`
      );
    }
  };

  const handimgleDelete = (index) => {
    setImages(images.filter((image, i) => i !== index));
    setImageCount(imageCount - 1);
  };

  // malty

  const [val, setval] = useState([]);

  const [checkboxdata, setcheckboxdata] = useState([]);

  const handlechange = (event) => {
    const value = event.target.checked;
    // console.log(value);
    if (event.target.checked) {
      setcheckboxdata([...checkboxdata, value]);
    } else {
      setcheckboxdata(checkboxdata.filter((val) => val !== value));
    }

    // console.log(event.target.checked);
  };

  // const handleDelete = (i) => {
  //   const detVel = [...val];
  //   // console.log("====================================");
  //   // console.log(val);
  //   // console.log("====================================");
  //   detVel.splice(i, 1);
  //   setval(detVel);
  // };

  // ------

  // const [mal, setMal] = useState([]);

  // const addEven = () => {
  //   const abc = [...mal, []];
  //   setMal(abc);
  // };

  // const handleChang = (onChangeValue, e) => {
  //   const inputData = [...mal];
  //   inputData[e] = onChangeValue.target.value;
  //   setMal(inputData);
  // };

  // const handleDelet = (e) => {
  //   const detMal = [...mal];
  //   // console.log("====================================");
  //   // console.log(val);
  //   // console.log("====================================");
  //   detMal.splice(e, 1);
  //   setMal(detMal);
  // };

  const handleSelect = async (value, { description }) => {
    // console.log(value);
    const results = await geocodeByAddress(value);
    // console.log(results);
    const latLng = await getLatLng(results[0]);
    setFieldValue("city", results[0]?.address_components[1]?.long_name);
    setFieldValue("state", results[0]?.address_components[2]?.long_name);
    setlat(latLng);
    setAddress(value);
  };

  // console.log(values);
  return (
    <>
      <LoginNavbar />
      {Model && <Popup show={Model} onHide={() => setModel(false)} />}
      <Payment show={show} onHide={() => setShow(false)} />
      <div>
        <span className="backbtn" onClick={() => Navigat(-1)}>
          <img src={back} alt="" />
        </span>
      </div>
      <section className="AddProperty-main-section">
        <div className="AddProperty-main-div">
          <form onSubmit={handleSubmit} className="selact-tag-div">
            <div className="Add-Property-div mb-4">
              <span className="add-propaty-span">Pause Add Property</span>
            </div>
            <div className="mb-4">
              <span className="Basic-Details-span">Basic Details</span>
            </div>
            <div className="main-input-div">
              <div className="eroor-input">
                <div className="labul-input ">
                  <label for="fname">Title</label>
                  <input
                    ref={inputref}
                    className="input-khud"
                    type="text"
                    id="fname"
                    name="title"
                    placeholder="Title"
                    value={values.title}
                    onBlur={handleBlur}
                    onChange={handle}
                  />
                </div>
                {errors.title && touched.title ? (
                  <p className="form-errror">{errors.title} </p>
                ) : null}
              </div>
              <div className="eroor-input">
                <div className="">
                  Address
                  <PlacesAutocomplete
                    value={address}
                    onChange={(value) => setAddress(value)}
                    onSelect={(value, suggestion) =>
                      handleSelect(value, suggestion)
                    }
                    searchOptions={{ types: ["geocode", "establishment"] }}
                    apiKey={"AIzaSyDJn3lkmc1GoVe1YMv0ZBzpUnLPlKnAeNQ"}
                  //
                  >
                    {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps,
                      loading,
                    }) => (
                      <div className="labul-input">
                        <input
                          {...getInputProps({
                            placeholder: "Enter your address",
                            className: "location-search-input",
                          })}
                          className="input-khud"
                        />
                        <div>
                          {loading && <div>Loading...</div>}
                          {suggestions.length > 0 && (
                            <div className="autocomplete-dropdown-container">
                              {loading && <div>Loading...</div>}
                              {suggestions.map((suggestion) => {
                                {
                                  /* console.log(suggestions); */
                                }
                                const className = suggestion.active
                                  ? "suggestion-item--active"
                                  : "suggestion-item";
                                const style = suggestion.active
                                  ? {
                                    backgroundColor: "#fafafa",
                                    cursor: "pointer",
                                  }
                                  : {
                                    backgroundColor: "#ffffff",
                                    cursor: "pointer",
                                  };
                                return (
                                  <div
                                    {...getSuggestionItemProps(suggestion, {
                                      className,
                                      style,
                                    })}
                                    className="addressdropdown"
                                  >
                                    <span>{suggestion?.description}</span>
                                  </div>
                                );
                              })}
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </PlacesAutocomplete>
                </div>
              </div>
              <div className="span-selact-rtag">
                <span>City</span>
                <input
                  className="input-khud"
                  type="text"
                  placeholder="Enter your city"
                  id="fname"
                  name="city"
                  // disabled={true}
                  // style={{ cursor: "not-allowed" }}
                  value={values.city}
                  onBlur={handleBlur}
                  onChange={handle}
                />
                {errors.city && touched.city ? (
                  <p className="form-errror">{errors.city} </p>
                ) : null}
              </div>
              <div className="span-selact-rtag mt-4">
                <span>State</span>
                <input
                  name="state"
                  // disabled={true}
                  // style={{ cursor: "not-allowed" }}
                  placeholder="Enter your state"
                  onBlur={handleBlur}
                  onChange={handle}
                  value={values.state}
                  className="input-khud"
                  type="text"
                />

                {errors.state && touched.state ? (
                  <p className="form-errror">{errors.state} </p>
                ) : null}
              </div>
              <div className="labul-input mt-4">
                <label for="fname">Zipcode</label>
                <input
                  className="input-khud"
                  type="number"
                  id="fname"
                  name="zipcode"
                  placeholder="Zipcode"
                  value={values.zipcode}
                  onBlur={handleBlur}
                  onChange={handle}
                />
              </div>
              <div className="span-selact-rtag mt-4">
                <span>Category</span>
                <div className="city-select-section">
                  <select
                    name="category"
                    onBlur={handleBlur}
                    onChange={handle}
                    value={values.category}
                    className="opan-div"
                  >
                    <option value="" disabled={true}>
                      Select category
                    </option>
                    {Getcategory?.map((itm, index) => {
                      return (
                        <option key={index} value={itm.id}>
                          {itm.title}
                        </option>
                      );
                    })}
                    ;
                  </select>
                </div>
                {errors.category && touched.category ? (
                  <p className="form-errror">{errors.category} </p>
                ) : null}
              </div>
            </div>
            <div className="Maximum-Accommodate-main-div">
              <div className="span-selact-rtag mt-4">
                <span>Maximum Adults Property Can Accommodate</span>
                <div className="Maximum-selact-main mt-2">
                  <select
                    name="Maximum"
                    onBlur={handleBlur}
                    onChange={handle}
                    value={values.Maximum}
                    className="Maximum-div-selact-tag"
                    defaultValue={"1"}
                  >
                    <option value="1" defaultChecked={true}>
                      1
                    </option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                  </select>
                </div>
                {errors.Maximum && touched.Maximum ? (
                  <p className="form-errror">{errors.Maximum} </p>
                ) : null}
              </div>
              <div className="span-selact-rtag mt-4">
                <span>Maximum Children Property Can Accommodate</span>
                <div className="Maximum-selact-main mt-2">
                  <select
                    name="Maximumc"
                    onBlur={handleBlur}
                    onChange={handle}
                    value={values.Maximumc}
                    className="Maximum-div-selact-tag-dusra"
                  >
                    <option value="0" defaultChecked={true}>
                      0
                    </option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                  </select>
                </div>
                {errors.Maximumc && touched.Maximumc ? (
                  <p className="form-errror">{errors.Maximumc} </p>
                ) : null}
              </div>
            </div>
            <div className="Add-Property-div mt-5">
              <span className="add-propaty-span">Property Details</span>
            </div>
            <div className="main-input-div-selact mt-4">
              <div className="mapmathard">
                <span className="night-span-tagg">Price per night</span>
                <div className="doller-sghin">
                  <span>€</span>
                  <input
                    className="input-khud-thard"
                    type="number"
                    id="fname"
                    name="price"
                    // placeholder="$"
                    value={values.price}
                    onBlur={handleBlur}
                    onChange={handle}
                    min="0"
                    onPaste={preventPasteNegative}
                    onKeyPress={preventMinus}
                  />
                </div>
                {errors.price && touched.price ? (
                  <p className="form-errror">{errors.price} </p>
                ) : null}
              </div>
              <div className="span-selact-rtag">
                <span>Property Size unit</span>
                <div className="city-select-thard-section">
                  <select
                    name="sizeunety"
                    onBlur={handleBlur}
                    onChange={handle}
                    value={values.sizeunety}
                    className="opan-div-selact-tag"
                  >
                    <option value="0">Property Size unit</option>
                    <option value="1"> Sq mt </option>
                    <option value="2">Sq ft</option>
                  </select>
                </div>
                {errors.sizeunety && touched.sizeunety ? (
                  <p className="form-errror">{errors.sizeunety} </p>
                ) : null}
              </div>
              <div className="mapmathard">
                <span className="night-gold">Property Size</span>
                <div className="doller-sghin">
                  <input
                    className="input-khud-thard"
                    type="number"
                    id="fname"
                    name="Size"
                    placeholder="Property Size"
                    onBlur={handleBlur}
                    onChange={handle}
                    value={values.Size}
                    min="0"
                    onPaste={preventPasteNegative}
                    onKeyPress={preventMinus}
                  />
                </div>
                {errors.Size && touched.Size ? (
                  <p className="form-errror">{errors.Size} </p>
                ) : null}
              </div>
            </div>
            <div>
              <form
                onSubmit={handleSubmit}
              >
                <div>
                  <div className="mt-3">
                    <span className="numBedrooms">Bedrooms:</span>
                  </div>
                  <div className="city-select-thard-section">
                    <select
                      className="opan-div-selact-tag"
                      id="numBedrooms"
                      value={numBedrooms}
                      onChange={(e) => {
                        setNumBedrooms(parseInt(e.target.value));
                        generateBedrooms(parseInt(e.target.value));
                      }}
                    >
                      {[...Array(11).keys()].slice(1).map(num => (
                        <option key={num} value={num}>{num}</option>
                      ))}
                    </select>
                  </div>
                  <div id="bedroomsContainer">
                    {bedrooms.map(bedroom => (
                      <div key={bedroom.id} style={{ marginBottom: "20px", marginTop: "20px" }}>
                        <h6>Bedroom {bedroom.id}</h6>
                        {bedroom.beds.map((bed, index) => (
                          <div key={bed.id} className="Select_merge_divv mt-3">
                            <div className="city-select-thard-section">
                              <select
                                value={bed.type}
                                onChange={(e) => handleBedTypeChange(bedroom.id, bed.id, e.target.value)}
                                className="opan-div-selact-tag"
                              >
                                <option value="" disabled>Select Bed Type</option>
                                {bedTypes.map((type) => (
                                  <option key={type} value={type}>
                                    {type}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className="city-select-thard-section">
                              <select
                                value={bed.qty}
                                onChange={(e) => handleBedQtyChange(bedroom.id, bed.id, parseInt(e.target.value))}
                                className="opan-div-selact-tag"
                              >
                                <option value="" disabled>Select Quantity</option>
                                {[...Array(11).keys()].slice(1).map(num => (
                                  <option key={num} value={num}>{num}</option>
                                ))}
                              </select>
                            </div>
                            {index !== 0 && (
                              <button
                                type="button"
                                onClick={() => removeBed(bedroom.id, bed.id)}
                                className="Del_expertise_btnn"
                              >
                                <img src={remove} alt="Remove" />
                              </button>
                            )}
                          </div>
                        ))}
                        <button
                          type="button"
                          onClick={() => addBed(bedroom.id)}
                          className="Add_expertise_btnn mt-2"
                        >
                          <div className="button_plus_divv">
                            <img src={plus} alt="Add" />
                            <span className="Adding_more">Add Bed</span>
                          </div>
                        </button>
                      </div>
                    ))}
                  </div>

                  <div className="mt-3">
                    <span className="numBedrooms">Bathrooms:</span>
                  </div>
                  <div className="city-select-thard-section">
                    <select
                      className="opan-div-selact-tag"
                      id="numBathrooms"
                      value={numBathrooms}
                      onChange={(e) => {
                        setNumBathrooms(parseInt(e.target.value));
                        generateBathrooms(parseInt(e.target.value));
                      }}
                    >
                      {[...Array(11).keys()].slice(1).map(num => (
                        <option key={num} value={num}>{num}</option>
                      ))}
                    </select>
                  </div>
                  <div id="bathroomsContainer">
                    {bathrooms.map(bathroom => (
                      <div key={bathroom.id} style={{ marginBottom: "20px", marginTop: "20px" }}>
                        <h6>Bathroom {bathroom.id}</h6>
                        {bathroom.beds.map((bed, index) => (
                          <div key={bed.id} className="Select_merge_divv mt-3">
                            <div className="city-select-thard-section">
                              <select
                                value={bed.type}
                                onChange={(e) => handleBedTypeChange(bathroom.id, bed.id, e.target.value, true)}
                                className="opan-div-selact-tag"
                              >
                                <option value="" disabled>Select Bathroom Type</option>
                                {bathroomTypes.map((type) => (
                                  <option key={type} value={type}>
                                    {type}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className="city-select-thard-section">
                              <select
                                value={bed.qty}
                                onChange={(e) => handleBedQtyChange(bathroom.id, bed.id, parseInt(e.target.value), true)}
                                className="opan-div-selact-tag"
                              >
                                <option value="" disabled>Select Quantity</option>
                                {[...Array(11).keys()].slice(1).map(num => (
                                  <option key={num} value={num}>{num}</option>
                                ))}
                              </select>
                            </div>
                            {index !== 0 && (
                              <button
                                type="button"
                                onClick={() => removeBed(bathroom.id, bed.id, true)}
                                className="Del_expertise_btnn"
                              >
                                <img src={remove} alt="Remove" />
                              </button>
                            )}
                          </div>
                        ))}
                        <button
                          type="button"
                          onClick={() => addBed(bathroom.id, true)}
                          className="Add_expertise_btnn mt-2"
                        >
                          <div className="button_plus_divv">
                            <img src={plus} alt="Add" />
                            <span className="Adding_more">Add Bathroom</span>
                          </div>
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
                {/* <button
                  type="button"
                  onClick={() => BedroomSubmit()}
                  style={{
                    marginTop: "10px",
                    padding: "5px 10px",
                    border: "none",
                    borderRadius: "3px",
                    backgroundColor: "#007bff",
                    color: "#fff",
                    cursor: "pointer",
                  }}
                >
                  Generate JSON
                </button> */}
              </form>
            </div>
            <div className="span-selact-rtag mt-4">
              <span>Cancellation Policy</span>
              {Loading ? (
                <CircularProgress />
              ) : (
                <div className="Cancellation-radio">
                  {Policies?.map((itm, index) => {
                    return (
                      <div className="redio-input" key={index}>
                        <input
                          type="radio"
                          id="radio"
                          name="policy"
                          checked={itm.id == policyid}
                          value={itm.id}
                          onChange={handle}
                          onBlur={handleBlur}
                          onClick={() => setpolicyid(itm.id)}
                        />
                        <label className="ht-ml-radio" htmlFor="">
                          {itm.policy}
                        </label>
                      </div>
                    );
                  })}
                </div>
              )}

              {errors.policy && touched.policy ? (
                <p className="form-errror">{errors.policy} </p>
              ) : null}
            </div>

            <div className="text-span-div">
              <div className="Write-here-span">
                <span>Neighborhood Description </span>
              </div>
              <textarea
                id="w3review"
                name="Neighborhood"
                rows="4"
                className="text-area-seconfd mt-2"
                cols="50"
                placeholder="Description"
                value={values.Neighborhood}
                onBlur={handleBlur}
                onChange={handle}
              ></textarea>
            </div>
            {errors.Neighborhood && touched.Neighborhood ? (
              <p className="form-errror">{errors.Neighborhood} </p>
            ) : null}

            <div className="text-span-div">
              <div className="Write-here-span">
                <span>Property Description </span>
              </div>
              <textarea
                id="w3review"
                name="Property"
                rows="4"
                className="text-area-seconfd mt-2"
                cols="50"
                placeholder="Description"
                value={values.Property}
                onBlur={handleBlur}
                onChange={handle}
              ></textarea>
            </div>
            {errors.Property && touched.Property ? (
              <p className="form-errror">{errors.Property} </p>
            ) : null}

            <div className="hawy-drayvor">
              <div className="plusbtn">
                <div className="mapmathard">
                  <div className="Amenities-div">
                    <span className="add-propaty-span">Amenities</span>
                  </div>
                  <div className="mt-3">
                    <div className="parentkaparent">
                      <div className="checkboxparent">
                        <span>Bedroom Essentials:</span>
                        {BedRooms.map((item, index) => {
                          return (
                            <>
                              <div className="checkdivmain" key={index}>
                                <span>{item.txt}</span>
                                <input
                                  type="checkbox"
                                  id={item.id}
                                  value={"bedsheets"}
                                  // ini---------------------------
                                  name="bedroomsothors"
                                  onClick={() => {
                                    if (item.id === 9) {
                                      setIsoterhbeds(!Isoterhbeds);
                                    }
                                    setBedRooms((preval) => {
                                      let temp = [...preval];
                                      let temp2 = temp[index];
                                      temp2 = {
                                        ...temp2,
                                        isSelected: !temp2.isSelected,
                                      };
                                      temp[index] = temp2;
                                      return temp;
                                    });
                                  }}
                                  checked={item.isSelected ? true : false}
                                  onChange={handlechange}
                                />
                              </div>
                            </>
                          );
                        })}
                        <div>
                          {Isoterhbeds ? (
                            <input
                              value={values.bedroomsothors}
                              onBlur={handleBlur}
                              onChange={handle}
                              name="bedroomsothors"
                              className="othars-input-txt"
                              type="text"
                            />
                          ) : null}
                        </div>
                        {/* <input type="text" /> */}
                        <hr />
                        <div className="checkboxparent">
                          <span>Accessibility</span>
                          {stepFree.map((item, index) => {
                            return (
                              <div className="checkdivmain" key={index}>
                                <span>{item.txt}</span>
                                <input
                                  type="checkbox"
                                  id={item.id}
                                  onClick={() => {
                                    setStepFree((preval) => {
                                      let temp = [...preval];
                                      let temp2 = temp[index];
                                      temp2 = {
                                        ...temp2,
                                        isSelected: !temp2.isSelected,
                                      };
                                      temp[index] = temp2;
                                      return temp;
                                    });
                                  }}
                                  checked={item.isSelected ? true : false}
                                  onChange={handlechange}
                                />
                              </div>
                            );
                          })}
                        </div>

                        <hr />
                        <div className="checkboxparent">
                          <span>Surroundings</span>
                          {gardenview.map((item, index) => {
                            return (
                              <div className="checkdivmain" key={index}>
                                <span>{item.txt}</span>
                                <input
                                  type="checkbox"
                                  id={item.id}
                                  // ini---------------------------
                                  name="gardenviewothors"
                                  onClick={() => {
                                    if (item.id === 14) {
                                      setIsotherView(!IsotherView);
                                    }
                                    setGardenview((preval) => {
                                      let temp = [...preval];
                                      let temp2 = temp[index];
                                      temp2 = {
                                        ...temp2,
                                        isSelected: !temp2.isSelected,
                                      };
                                      temp[index] = temp2;
                                      return temp;
                                    });
                                  }}
                                  checked={item.isSelected ? true : false}
                                  onChange={handlechange}
                                />
                              </div>
                            );
                          })}
                          <div>
                            {IsotherView ? (
                              <input
                                value={values.gardenviewothors}
                                onBlur={handleBlur}
                                onChange={handle}
                                name="gardenviewothors"
                                type="text"
                                className="othars-input-txt"
                              />
                            ) : null}
                          </div>

                          <hr />
                          <span className="Facilities-all">Families</span>
                          {Familie.map((item, index) => {
                            return (
                              <div className="checkdivmain" key={index}>
                                <span>{item.txt}</span>
                                <input
                                  type="checkbox"
                                  id={item.id}
                                  onClick={() => {
                                    setfamilie((preval) => {
                                      let temp = [...preval];
                                      let temp2 = temp[index];
                                      temp2 = {
                                        ...temp2,
                                        isSelected: !temp2.isSelected,
                                      };
                                      temp[index] = temp2;
                                      return temp;
                                    });
                                  }}
                                  checked={item.isSelected ? true : false}
                                  onChange={handlechange}
                                />
                              </div>
                            );
                          })}
                          <hr />
                          <span className="Facilities-all">Security</span>
                          {security.map((item, index) => {
                            return (
                              <div className="checkdivmain" key={index}>
                                <span>{item.txt}</span>
                                <input
                                  type="checkbox"
                                  id={item.id}
                                  onClick={() => {
                                    setSecurity((preval) => {
                                      let temp = [...preval];
                                      let temp2 = temp[index];
                                      temp2 = {
                                        ...temp2,
                                        isSelected: !temp2.isSelected,
                                      };
                                      temp[index] = temp2;
                                      return temp;
                                    });
                                  }}
                                  checked={item.isSelected ? true : false}
                                  onChange={handlechange}
                                />
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      <div className="checkboxparent">
                        <span>Bathroom Essentials:</span>
                        {Bathroom.map((item, index) => {
                          return (
                            <div className="checkdivmain" key={index}>
                              <span>{item.txt}</span>
                              <input
                                type="checkbox"
                                id={item.id}
                                // ini-------------------
                                name="bathroomothars"
                                onClick={() => {
                                  if (item.id === 444) {
                                    setIsotherBathRooms(!IsotherBathRooms);
                                  }
                                  setBathroom((preval) => {
                                    let temp = [...preval];
                                    let temp2 = temp[index];
                                    temp2 = {
                                      ...temp2,
                                      isSelected: !temp2.isSelected,
                                    };
                                    temp[index] = temp2;
                                    return temp;
                                  });
                                }}
                                checked={item.isSelected ? true : false}
                                onChange={handlechange}
                              />
                            </div>
                          );
                        })}
                        <div>
                          {IsotherBathRooms ? (
                            <input
                              value={values.bathroomothars}
                              onBlur={handleBlur}
                              onChange={handle}
                              name="bathroomothars"
                              className="othars-input-txt"
                              type="text"
                            />
                          ) : null}
                        </div>
                        <hr />

                        <div className="checkboxparent">
                          <span>Air Conditioning</span>
                          {Aircondi.map((item, index) => {
                            return (
                              <div className="checkdivmain" key={index}>
                                <span>{item.txt}</span>
                                <input
                                  type="checkbox"
                                  id={item.id}
                                  // ini---------------------------
                                  name="aircondiothors"
                                  onClick={() => {
                                    if (item.id === 7869) {
                                      setAirconditioning(!airconditioning);
                                    }
                                    setAircondi((preval) => {
                                      let temp = [...preval];
                                      let temp2 = temp[index];
                                      temp2 = {
                                        ...temp2,
                                        isSelected: !temp2.isSelected,
                                      };
                                      temp[index] = temp2;
                                      return temp;
                                    });
                                  }}
                                  checked={item.isSelected ? true : false}
                                  onChange={handlechange}
                                />
                              </div>
                            );
                          })}
                          <div>
                            {airconditioning ? (
                              <input
                                value={values.aircondiothors}
                                onBlur={handleBlur}
                                onChange={handle}
                                name="aircondiothors"
                                className="othars-input-txt"
                                type="text"
                              />
                            ) : null}
                          </div>
                          <hr />
                          <div className="checkboxparent">
                            <span>Check in method:</span>
                            {checkinMethod.map((item, index) => {
                              return (
                                <div className="checkdivmain" key={index}>
                                  <span className="chack-in-span">
                                    {item.txt}
                                  </span>
                                  <input
                                    type="checkbox"
                                    id={item.id}
                                    // ini---------------------------
                                    name="checkinMethodothors"
                                    onClick={() => {
                                      if (item.id === 444) {
                                        setIsotherCheckMethod(
                                          !IsotherCheckMethod
                                        );
                                      }
                                      setCheckinMethod((preval) => {
                                        let temp = [...preval];
                                        let temp2 = temp[index];
                                        temp2 = {
                                          ...temp2,
                                          isSelected: !temp2.isSelected,
                                        };
                                        temp[index] = temp2;
                                        return temp;
                                      });
                                    }}
                                    checked={item.isSelected ? true : false}
                                    onChange={handlechange}
                                  />
                                </div>
                              );
                            })}

                            <div>
                              {IsotherCheckMethod ? (
                                <input
                                  className="othars-input-txt"
                                  placeholder=""
                                  name="checkinMethodothors"
                                  type="text"
                                  value={values.checkinMethodothors}
                                  onBlur={handleBlur}
                                  onChange={handle}
                                />
                              ) : null}
                            </div>
                            <div className="m-2"></div>
                          </div>
                        </div>
                      </div>
                      <div className="checkboxparent">
                        <span>Kitchen:</span>
                        {kitchan.map((item, index) => {
                          return (
                            <div className="checkdivmain" key={index}>
                              <span>{item.txt}</span>
                              <input
                                type="checkbox"
                                id={item.id}
                                // ini---------------------------
                                name="kitchanothors"
                                onClick={() => {
                                  if (item.id === 10119) {
                                    setIsotherKichen(!IsotherKichen);
                                  }
                                  setkitchan((preval) => {
                                    let temp = [...preval];
                                    let temp2 = temp[index];
                                    temp2 = {
                                      ...temp2,
                                      isSelected: !temp2.isSelected,
                                    };
                                    temp[index] = temp2;
                                    return temp;
                                  });
                                }}
                                checked={item.isSelected ? true : false}
                                onChange={handlechange}
                              />
                            </div>
                          );
                        })}

                        <div>
                          {IsotherKichen ? (
                            <input
                              name="kitchanothors"
                              value={values.kitchanothors}
                              onBlur={handleBlur}
                              onChange={handle}
                              className="othars-input-txt"
                              type="text"
                            />
                          ) : null}
                        </div>

                        <div className="m-2"></div>
                        <hr />
                        <span>
                          <b>Share space</b>
                        </span>
                        {sharespace.map((item, index) => {
                          return (
                            <div className="checkdivmain" key={index}>
                              <span>{item.txt}</span>
                              <input
                                type="checkbox"
                                id={item.id}
                                name="amenities"
                                onClick={() => {
                                  if (item.id === 444222) {
                                    setSharespace1(!sharespace1);
                                  }
                                  setsharespace((preval) => {
                                    let temp = [...preval];
                                    let temp2 = temp[index];
                                    temp2 = {
                                      ...temp2,
                                      isSelected: !temp2.isSelected,
                                    };
                                    temp[index] = temp2;
                                    return temp;
                                  });
                                }}
                                checked={item.isSelected ? true : false}
                                onChange={handlechange}
                              />
                            </div>
                          );
                        })}
                      </div>
                      <div className="checkboxparent">
                        <span>Children:</span>
                        {Child.map((item, index) => {
                          return (
                            <div className="checkdivmain" key={index}>
                              <span>{item.txt}</span>
                              <input
                                type="checkbox"
                                id={item.id}
                                // ini---------------------------
                                name="childothors"
                                onClick={() => {
                                  if (item.id === 455) {
                                    setIsotherChildern(!IsotherChildern);
                                  }
                                  Setchild((preval) => {
                                    let temp = [...preval];
                                    let temp2 = temp[index];
                                    temp2 = {
                                      ...temp2,
                                      isSelected: !temp2.isSelected,
                                    };
                                    temp[index] = temp2;
                                    return temp;
                                  });
                                }}
                                checked={item.isSelected ? true : false}
                                onChange={handlechange}
                              />
                            </div>
                          );
                        })}
                        <div>
                          {IsotherChildern ? (
                            <input
                              value={values.childothors}
                              onBlur={handleBlur}
                              onChange={handle}
                              name="childothors"
                              className="othars-input-txt"
                              type="text"
                            />
                          ) : null}
                        </div>
                        <div></div>
                        <hr />
                        <span className="Facilities-all">Facilities</span>
                        {kitchenette.map((item, index) => {
                          return (
                            <div className="checkdivmain" key={index}>
                              <span>{item.txt}</span>
                              <input
                                type="checkbox"
                                id={item.id}
                                // ini---------------------------
                                name="cleanFessothors"
                                onClick={() => {
                                  if (item.id === 6552) {
                                    setIsOtherCleanFess(!IsOtherCleanFess);
                                  }
                                  setketKitchenette((preval) => {
                                    let temp = [...preval];
                                    let temp2 = temp[index];
                                    temp2 = {
                                      ...temp2,
                                      isSelected: !temp2.isSelected,
                                    };
                                    temp[index] = temp2;
                                    return temp;
                                  });
                                }}
                                checked={item.isSelected ? true : false}
                                value={"bedsheets"}
                                onChange={handlechange}
                              />
                            </div>
                          );
                        })}
                        <div>
                          {IsotherKichen1 ? (
                            <input placeholder="Enter CleanFess" type="text" />
                          ) : null}
                        </div>
                        {IsOtherCleanFess ? (
                          <input className="othars-input-txt" type="text" />
                        ) : null}

                        <div>
                          <label className="cleaning-services-Free-in-le">
                            Free cleaning services
                            <input
                              type="checkbox"
                              onChange={() => handleCheckboxChange("0")}
                              onClick={() => setFree(!free)}
                            // disabled={selectedCheckBox == "checkbox2"}
                            />
                          </label>
                          <br />
                          <label className="cleaning-services-Free-in-le">
                            Cleaning fees
                            <input
                              className="checkbox-ab-se-khrab"
                              type="checkbox"
                              onChange={() => handleCheckboxChange("1")}

                            // disabled={selectedCheckBox == "checkbox1"}
                            />
                          </label>
                          {showInput ? (
                            <input
                              type="number"
                              value={cleningFee}
                              onChange={(e) => {
                                setCleningFee(e.target.value);
                              }}
                              className="othars-input-txt"
                              placeholder="Enter text"
                              min="0"
                              name="cleaning"
                              onPaste={preventPasteNegative}
                              onKeyPress={preventMinus}
                            />
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <span className="Facilities-all">House Rules</span>
              <div className="between-time mb-2">
                <span className="">Check in time </span>
                <TimePicker onChange={onChangeeee} value={valueeeee} />
              </div>
              <div className="out-time">
                <span className="Check-out-all">Check out time_</span>
                <TimePicker onChange={onOut} value={out} />
              </div>
              <div className="noise-time">
                <span className="Check-out-all">No noise after</span>
                <TimePicker onChange={onNoise} value={noise} />
              </div>
              <div className="Swimming-time">
                <span className="Check-out-all">Swimming pool times from</span>
                <TimePicker onChange={onPool} value={pool} />
              </div>
              <div className="tub-time mb-5">
                <span className="Check-out-all"> Hot tub times from</span>
                <TimePicker onChange={onTub} value={tub} />
              </div>

              {House.map((item, index) => {
                return (
                  <div className="HouseRules-chake-box" key={index}>
                    <span className="Spann-checkboxx-sett">{item.txt}</span>
                    <input
                      type="checkbox"
                      id={item.id}
                      name="Houseruleshr"
                      onClick={() => {
                        if (item.id === 5023) {
                          setIsotherHouseRules(!IsotherHouseRules);
                        } else if (item.id === 5021) {
                          setDispose(!Dispose);
                        }
                        setHouse((preval) => {
                          let temp = [...preval];
                          let temp2 = temp[index];
                          temp2 = {
                            ...temp2,
                            isSelected: !temp2.isSelected,
                          };
                          temp[index] = temp2;
                          return temp;
                        });
                      }}
                      checked={item.isSelected ? true : false}
                      onChange={handlechange}
                    />
                  </div>
                );
              })}
              <div>
                {Dispose ? (
                  <input
                    placeholder="Instruction"
                    type="text"
                    className="othars-input-txt"
                  />
                ) : null}
              </div>
              <div className="mt-3">
                {IsotherHouseRules ? (
                  <input
                    className="othars-input-txt"
                    placeholder="Other"
                    type="text"
                  />
                ) : null}
              </div>
            </div>
            <FixedButton />
            <div>
              <div className="mt-5">
                <span className="photo-add-span">Property Photos</span>
              </div>
              <div className="mt-3">
                <span className="add-photo-span">Add photos</span>
              </div>
              <div className="add-delet-option">
                <label
                  className="Ht-ml-for"
                  htmlFor={"mal"}
                  style={{ cursor: "pointer" }}
                >
                  {" "}
                  <img className="mal-te-img" src={Mal} alt="" />
                </label>
                <input
                  id="mal"
                  type="file"
                  multiple
                  onChange={handleImageChange}
                  className="Malte-pal-cls"
                />
                {/* <div>
                  <img src={Mal} multiple onChange={handleImageChange} alt="" />
                </div> */}
                <div className="overflowing-add-immgg">
                  {images?.map((image, index) => {
                    let imgURL = URL.createObjectURL(image);
                    return (
                      <div key={index} className="sab-img-ka-div">
                        <img
                          src={imgURL}
                          alt={`Image-${index}`}
                          className="add-kiya-huva-pic"
                          style={{ width: "79.71px", height: "79.71px" }}
                        />
                        <button
                          className="Btn-dada-delet"
                          onClick={() => handimgleDelete(index)}
                        >
                          <svg
                            className="Ma-svg-top"
                            width="14"
                            height="14"
                            viewBox="0 0 14 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clip-path="url(#clip0_25_3172)">
                              <path
                                d="M7.0014 6.17516L9.8889 3.28766L10.7137 4.11249L7.82623 6.99999L10.7137 9.88749L9.8889 10.7123L7.0014 7.82483L4.1139 10.7123L3.28906 9.88749L6.17656 6.99999L3.28906 4.11249L4.1139 3.28766L7.0014 6.17516Z"
                                fill="#211B24"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_25_3172">
                                <rect width="14" height="14" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </button>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="add-prop-btn-div">
              <button
                type="submit"
                onClick={handleButton2Click}
                className="Add-Propertybtn-seco"
              >
                {loading ? "Loading.." : "Add Property"}
              </button>
            </div>
          </form>
        </div>
      </section>
      {!loading && <div></div>}
      {loading && (
        <div>
          <Backdrop
            sx={{
              color: "#9c04f5",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={true}
          // onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}
      <Footer />
    </>
  );
};

export default PauseAddProperty;
