import React, { useState, useEffect } from "react";
import axios from "../Schemas/Api";
import { Backdrop, CircularProgress } from "@mui/material";

const TransactionHistory = () => {
  const token = JSON.parse(localStorage.getItem("login"));
  const [conproperty, setConproperty] = useState([]);
  const [Loading, setLoading] = useState(false);

  const GetSavelist = () => {
    setLoading(true);
    axios({
      method: "get",
      url: "/user/display_payout",
      headers: {
        "Content-Type": "application/json",
        authorization: `${token.data.token.token}`,
      },
    })
      .then((res) => res.data)
      .then((data) => {
        setLoading(false);
        setConproperty(data.content);
        // console.log("beta !!!!!", data.content);
      })
      .catch((err) => {
        setLoading(false);
        // console.log(err);
      });
  };
  useEffect(() => {
    GetSavelist();
  }, []);
  return (
    <>
      <section className="PaymentPayouts-main-section">
        <div className="PaymentPayouts-main-div">
          <span className="pymanbt-span">About your payouts</span>
          <span
            className="mt-4"
            dangerouslySetInnerHTML={{ __html: conproperty.data }}
          ></span>
        </div>
      </section>
      {Loading && (
        <div>
          <Backdrop
            sx={{ color: "#9c04f5", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
          // onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}
    </>
  );
};

export default TransactionHistory;
